import React, { useState } from 'react';

const GlobalContext = React.createContext();

const themeConfigDefault = {
  headerDark: true,
  bodyDark: true,
  footerDark: true
};

const deviceAgent =
  typeof window === 'undefined'
    ? 'NO_MATCH'
    : window.navigator.userAgent.toLowerCase();

const mobile =
  deviceAgent.match(/(iphone|ipod|ipad)/) ||
  deviceAgent.match(/(android)/) ||
  deviceAgent.match(/(iemobile)/) ||
  deviceAgent.match(/iphone/i) ||
  deviceAgent.match(/ipad/i) ||
  deviceAgent.match(/ipod/i) ||
  deviceAgent.match(/blackberry/i) ||
  deviceAgent.match(/bada/i);

// console.info('mobile: ' + (mobile ? true : false));

const GlobalProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [showHomeHeroText, setShowHomeHeroText] = useState(false);

  // const changeTheme = (themeConfig = themeConfigDefault) => {
  //   setTheme({
  //     ...themeConfig,
  //   });
  // };

  // const toggleVideoModal = () => {
  //   setVideoModalVisible(!videoModalVisible);
  // };

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
  };

  const closeAbout = () => {
    setAboutVisible(false);
  };

  const toggleContact = () => {
    setContactVisible(!contactVisible);
  };

  const closeContact = () => {
    setContactVisible(false);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        // changeTheme,
        videoModalVisible,
        // toggleVideoModal,
        aboutVisible,
        toggleAbout,
        closeAbout,
        contactVisible,
        toggleContact,
        closeContact,
        visibleOffCanvas,
        toggleOffCanvas,
        showHomeHeroText,
        setShowHomeHeroText,
        mobile: mobile ? true : false
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
