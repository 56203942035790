import React from 'react';
import styled from 'styled-components';
import {
  FiYoutube,
  FiInstagram,
  // FaFacebookF,
  // FaTwitter,
  // FaLinkedinIn,
  // FaGithub,
} from "react-icons/fi"; // https://github.com/react-icons/react-icons
import { Button } from "react-bootstrap";

const icons = {
  Instagram: <FiInstagram />,
  YouTube: <FiYoutube />,
  // Facebook: <FaFacebookF />,
  // Twitter: <FaTwitter />,
  // LinkedIn: <FaLinkedinIn />,
  // Github: <FaGithub />,
};

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isFooter }) => isFooter ? '0' : '1rem' };
  margin-bottom: ${({ isFooter }) => isFooter ? '0' : '2rem' };
  gap: 0 1rem;

  .about-content & {    
    justify-content: flex-start;
    padding: 10px 0;
    gap: 0 15px;
  }

  .btn {    
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 0.4rem;
    border: none;
    /* background: rgba(255,255,255,0.1); */
    background: ${({ theme }) => theme.colors.brightYellow};

    svg {
      color: #fff;
      height: 24px;
      width: 24px;
      display: block;
      stroke-width: 1px;
    }
    &:hover {
      background: var(--golden-gate-bridge);
    }
    :hover svg {
      color: #fff;      
    }

    &.inverse {
      background: rgba(255,255,255,0.05);
      &:hover {
        background: ${({ theme }) => theme.colors.brightYellow };
      }
      svg {
        color: ${({ theme }) => theme.colors.brightYellow };   
      }
      &:hover svg {
        color: ${({ theme }) => theme.colors.veryDarkBlue };     
      }
    }
  }
`;

const Social = ({ items, outline, inverse, className, ...rest }) => {  
  return (
    <SocialContainer className={`d-flex ${ className ? className : '' }`} { ...rest }>
      { items.map((item) => (
        <div key={ item.title }>
          <Button
            variant={"outline-primary"}
            size="sm"
            href={ item.url }
            target="_blank"
            rel="noopener noreferrer"
            className={inverse && "inverse"}
          >
            { icons[item.title] }
          </Button>
        </div>
      ))}
    </SocialContainer>
  );
};

export default Social;
