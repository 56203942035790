import {
  css
}

from 'styled-components';

/*
variables that generated this file:

$primary: #495fef;
$secondary: #f5f5f7;
$success: #56b381;
$bg: #ffffff;
$heading: #161c2d;
$text: #161c2d;
$white: $bg;
$black: $heading;
$body-color: $text;
$headings-color: $text;
$body-bg: $bg;
// $font-family-base: "Inter", sans-serif;
$font-family-base: "Okine", sans-serif;
$headings-font-family: $font-family-base;
$headings-font-weight: 600;

$grid-gutter-width: 0; // https://getbootstrap.com/docs/5.0/layout/gutters/
*/

export default css`
  /* kbk colors */
  :root {
    /* https://coolors.co/ffd500-ff8c61-ce6a85-985277-5c374c */
    ${'' /* --gold: hsla(50, 100%, 50%, 1);
    --coral: hsla(16, 100%, 69%, 1);
    --blush: hsla(344, 51%, 61%, 1);
    --magenta-haze: hsla(328, 30%, 46%, 1);
     */}

    /* https://coolors.co/ffd500-666a86-788aa3-92b6b1-ff715b */
    /* --gold: hsla(50, 100%, 50%, 1); */
    ${'' /*

    --cambridge-blue: hsla(172, 20%, 64%, 1);
    --bittersweet: hsla(8, 100%, 68%, 1); */}

    /* https://coolors.co/ffd500-ba5624-381d2a-b4adea-59ffa0 */
    /* --gold: hsla(50, 100%, 50%, 1); */
    ${'' /* --burnt-orange: hsla(20, 68%, 44%, 1);
    --dark-purple: hsla(331, 32%, 17%, 1);
    --periwinkle: hsla(247, 59%, 80%, 1);
    --spring-green: hsla(146, 100%, 67%, 1); */}

    /* https://coolors.co/ffd500-083d77-da4167-f78764-fec9f1 */
    /* --gold: hsla(50, 100%, 50%, 1); */
    ${'' /* --yale-blue: hsla(211, 87%, 25%, 1);
    --cerise: hsla(345, 67%, 55%, 1); */}
    /* --coral: hsla(14, 90%, 68%, 1); */
    ${'' /* --pink-lavender: hsla(315, 96%, 89%, 1); */}

    /* https://coolors.co/ffd500-08605f-177e89-598381-2f0a28 */
    /* --gold: hsla(50, 100%, 50%, 1); */
    ${'' /* --caribbean-current: hsla(179, 85%, 20%, 1);
    --teal: hsla(186, 71%, 31%, 1);
    --hookers-green: hsla(177, 19%, 43%, 1); */}
    /* --dark-purple: hsla(311, 65%, 11%, 1); */

    /* https://coolors.co/ffd500-131620-738290-f24c00-2f0a28 */
    /* --gold: hsla(50, 100%, 50%, 1); */
    --rich-black: hsla(226, 25%, 10%, 1);
    /* --slate-gray: hsla(209, 12%, 51%, 1); */
    --golden-gate-bridge: hsla(19, 100%, 47%, 1);
    /* --dark-purple: hsla(311, 65%, 11%, 1); */
    --paynes-gray: hsla(233, 14%, 46%, 1);
    --slate-gray: hsla(215, 19%, 55%, 1);
    --coral: hsla(14, 90%, 68%, 1);

    /* KBK COLORS */
    ${'' /* --nav-light-blue: hsla(225, 50%, 70%, 1.000); */}
    --nav-light-blue: hsla(225, 10%, 70%, 1.000);
    --nav-dark-blue: hsla(225, 50%, 50%, 1.000);
    --creative-gray: #aeb2bd; /* gray color of "creative" text in logo */
    --light-border-gray: rgba(125, 125, 125, 0.2);
    --lighter-yellow: hsla(50, 100%, 80%, 1.000);
  }

  /* bootstrap stuff below */
  :root {
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
  }

/*
  :root,
  [data-bs-theme=light] {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #161c2d;
    --bs-white: #ffffff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #495fef;
    --bs-secondary: #f5f5f7;
    --bs-success: #56b381;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 73, 95, 239;
    --bs-secondary-rgb: 245, 245, 247;
    --bs-success-rgb: 86, 179, 129;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #1d2660;
    --bs-secondary-text-emphasis: #626263;
    --bs-success-text-emphasis: #224834;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #dbdffc;
    --bs-secondary-bg-subtle: #fdfdfd;
    --bs-success-bg-subtle: #ddf0e6;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #b6bff9;
    --bs-secondary-border-subtle: #fbfbfc;
    --bs-success-border-subtle: #bbe1cd;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 22, 28, 45;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: "Okine", sans-serif;
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #161c2d;
    --bs-body-color-rgb: 22, 28, 45;
    --bs-body-bg: #ffffff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #161c2d;
    --bs-emphasis-color-rgb: 22, 28, 45;
    --bs-secondary-color: rgba(22, 28, 45, 0.75);
    --bs-secondary-color-rgb: 22, 28, 45;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(22, 28, 45, 0.5);
    --bs-tertiary-color-rgb: 22, 28, 45;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: #161c2d;
    --bs-link-color: #495fef;
    --bs-link-color-rgb: 73, 95, 239;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #3a4cbf;
    --bs-link-hover-color-rgb: 58, 76, 191;
    --bs-code-color: #d63384;
    --bs-highlight-color: #161c2d;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(22, 28, 45, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(22, 28, 45, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(22, 28, 45, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(73, 95, 239, 0.25);
    --bs-form-valid-color: #56b381;
    --bs-form-valid-border-color: #56b381;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
  }

  [data-bs-theme=dark] {
    color-scheme: dark;
    --bs-body-color: #dee2e6;
    --bs-body-color-rgb: 222, 226, 230;
    --bs-body-bg: #212529;
    --bs-body-bg-rgb: 33, 37, 41;
    --bs-emphasis-color: #ffffff;
    --bs-emphasis-color-rgb: 255, 255, 255;
    --bs-secondary-color: rgba(222, 226, 230, 0.75);
    --bs-secondary-color-rgb: 222, 226, 230;
    --bs-secondary-bg: #343a40;
    --bs-secondary-bg-rgb: 52, 58, 64;
    --bs-tertiary-color: rgba(222, 226, 230, 0.5);
    --bs-tertiary-color-rgb: 222, 226, 230;
    --bs-tertiary-bg: #2b3035;
    --bs-tertiary-bg-rgb: 43, 48, 53;
    --bs-primary-text-emphasis: #929ff5;
    --bs-secondary-text-emphasis: #f9f9fa;
    --bs-success-text-emphasis: #9ad1b3;
    --bs-info-text-emphasis: #6edff6;
    --bs-warning-text-emphasis: #ffda6a;
    --bs-danger-text-emphasis: #ea868f;
    --bs-light-text-emphasis: #f8f9fa;
    --bs-dark-text-emphasis: #dee2e6;
    --bs-primary-bg-subtle: #0f1330;
    --bs-secondary-bg-subtle: #313131;
    --bs-success-bg-subtle: #11241a;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c0b0e;
    --bs-light-bg-subtle: #343a40;
    --bs-dark-bg-subtle: #252b37;
    --bs-primary-border-subtle: #2c398f;
    --bs-secondary-border-subtle: #939394;
    --bs-success-border-subtle: #346b4d;
    --bs-info-border-subtle: #087990;
    --bs-warning-border-subtle: #997404;
    --bs-danger-border-subtle: #842029;
    --bs-light-border-subtle: #495057;
    --bs-dark-border-subtle: #343a40;
    --bs-heading-color: inherit;
    --bs-link-color: #929ff5;
    --bs-link-hover-color: #a8b2f7;
    --bs-link-color-rgb: 146, 159, 245;
    --bs-link-hover-color-rgb: 168, 178, 247;
    --bs-code-color: #e685b5;
    --bs-highlight-color: #dee2e6;
    --bs-highlight-bg: #664d03;
    --bs-border-color: #495057;
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
    --bs-form-valid-color: #75b798;
    --bs-form-valid-border-color: #75b798;
    --bs-form-invalid-color: #ea868f;
    --bs-form-invalid-border-color: #ea868f;
  }
  */

`;