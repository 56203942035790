import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ReactSVG } from 'react-svg';

import chevronDown from '/src/assets/svg/ui/chevron-down.svg';

const Dropdown = styled.li`
  position: relative;

  &:has(.nav-link-active) .nav-link:after {
    opacity: 0 !important;
  }

  .nav-link {
    /* gap: 0 2px; */
    cursor: default;

    svg {
      height: 22px;
      width: 22px;
      display: block;
      transform: translateY(1px);
    }

    &.nav-link-active {
      color: var(--golden-gate-bridge);
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    left: -12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 12px;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition:
      height 250ms ease,
      opacity 450ms ease,
      box-shadow 650ms ease;

    &.dd-menu-show {
      opacity: 1;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    a {
      display: block;
      padding: 6px 0;
      font-size: 16px;
      line-height: 1;
      white-space: nowrap;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const menuVertPadding = 12;

const calcMenuH = (ddMenu) => {
  return [...ddMenu.children].reduce((acc, link) => {
    return (acc = acc + link.offsetHeight);
  }, 0);
};

const DropdownMenu = () => {
  const [menuH, setMenuH] = useState(0);
  const ddMenuRef = useRef(null);

  const showMenu = () => {
    setMenuH(menuVertPadding + calcMenuH(ddMenuRef.current));
  };

  const hideMenu = () => {
    setMenuH(0);
  };

  // const toggleDdMenu = () => {
  //   const newH = menuH > 0 ? 0 : menuVertPadding + calcMenuH(ddMenuRef.current);
  //   setMenuH(newH);
  // };

  return (
    <Dropdown
      className="nav-item"
      onMouseEnter={showMenu}
      onMouseLeave={hideMenu}
    >
      <button
        type="button"
        className={`nav-link ${menuH > 0 ? 'nav-link-active' : ''}`}
        onClick={() => {
          menuH > 0 ? hideMenu() : showMenu();
        }}
      >
        <span>portfolio</span>
        <ReactSVG src={chevronDown} wrapper="span" />
      </button>

      <div
        className={`dd-menu ${menuH > 0 ? 'dd-menu-show' : ''}`}
        ref={ddMenuRef}
        style={{ height: menuH + 'px' }}
      >
        <Link to="/work" onClick={hideMenu}>
          recent work
        </Link>
        <Link to="/photo" onClick={hideMenu}>
          photo
        </Link>
        <Link to="/video" onClick={hideMenu}>
          video
        </Link>
        {/* <a
          href="https://www.instagram.com/kristiankeane/"
          target="_blank"
          rel="noreferrer"
          onClick={hideMenu}
        >
          social
        </a> */}
      </div>
    </Dropdown>
  );
};

export default DropdownMenu;
