import React, { useContext } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'gatsby';
import { ReactSVG } from 'react-svg';

import GlobalContext from "../../context/GlobalContext";
import chevronRight from '/src/assets/svg/ui/chevron-right.svg';
import chevronDown from '/src/assets/svg/ui/chevron-down.svg';

const NestedMenuContainer = styled.div`
  a {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    transition: all 0.3s ease-out;
    font-weight: 400;
    font-size: 20px;
    text-transform: lowercase;
    text-decoration: none;
    padding: 10px 8px;
    line-height: 20px;
    width: fit-content;
    display: flex;
    gap: 0 1px;
    align-items: center;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.veryDarkBlue };
      background-color: ${({ theme }) => theme.colors.brightYellow};            
      text-decoration: underline;      
    }

    svg {
      height: 22px;
      width: 22px;
      display: block;
      transform: translateY(2px);
    }
  }

  .nested-items {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 12px 12px 12px;
  }


  /* .list-group-item + .list-group-item {
    border-top: 1px solid rgba(0,0,0,0.15);
  } */
`;

const NestedMenu = () => {
  const gContext = useContext(GlobalContext);
  return (
    <NestedMenuContainer>

      <ListGroup variant="flush">

        <ListGroup.Item
          css={`
            padding-left: 0px !important;
            padding-right: 0 !important;
          `}
        >
          {
            <Link to="/" onClick={() => {                
              if (gContext.visibleOffCanvas) {
                gContext.toggleOffCanvas();
              }
            }}>home</Link>
          }
        </ListGroup.Item>

        <ListGroup.Item
          css={`
            padding-left: 0px !important;
            padding-right: 0 !important;
          `}
        >
          {
            <Link
              to="/how-it-works"
              onClick={() => {                
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              how it works
            </Link>
          }
        </ListGroup.Item>        

        <ListGroup.Item
          css={`
            padding-left: 0px !important;
            padding-right: 0 !important;
          `}
        >
            <Link to="/" style={{ pointerEvents: 'none' }}>
              <span>portfolio</span>
              <ReactSVG src={ chevronDown } wrapper="span" />  
            </Link>

          <div className="nested-items">
          
            <Link to="/work" onClick={() => {                
              if (gContext.visibleOffCanvas) {
                gContext.toggleOffCanvas();
              }
            }}>              
              <span>recent work</span>
            </Link>

            <Link to="/video" onClick={() => {                
              if (gContext.visibleOffCanvas) {
                gContext.toggleOffCanvas();
              }
            }}>              
              <span>video</span>
            </Link>

            <Link to="/photo" onClick={() => {                
              if (gContext.visibleOffCanvas) {
                gContext.toggleOffCanvas();
              }
            }}>              
              <span>photo</span>
            </Link>       
          </div>                 
          
        </ListGroup.Item>                         
        
        <ListGroup.Item
          css={`
            padding-left: 0px !important;
            padding-right: 0 !important;
          `}
        >
          {
            <Link
              to="/about"
              onClick={() => {                
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              about
            </Link>
          }
        </ListGroup.Item>
        
        <ListGroup.Item
          css={`
            padding-left: 0px !important;
            padding-right: 0 !important;
          `}
        >
          {
            <Link
              to="/contact"
              onClick={() => {                
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              contact
            </Link>
          }
        </ListGroup.Item>
      </ListGroup>
    </NestedMenuContainer>
  );
};

export default NestedMenu;
