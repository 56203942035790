import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { device } from '../../utils';

const YellowLineStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 300;
  outline: none;
  border: none;
  border-radius: 3px;
  z-index: 3;
  cursor: pointer;
  background: transparent;
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.veryDarkBlue };
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brightYellow };
  transition: 
    opacity 250ms ease-in-out,
    box-shadow 250ms ease-in-out,
    background 250ms ease-in-out,
    color 250ms ease-in-out;

  @media ${device.lg} {
    font-size: 2rem;
  }    
  /* & + button {
    margin-left: 2rem;
  } */
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.brightYellow };
  }
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.done,
  &.done.red-line-btn {
    color: ${({ theme }) => theme.colors.primary };
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary };
    &:hover {
      background: #fff;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary };
    }
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
    display: block;  
    @media ${device.lg} {
      width: 2rem;
      height: 2rem;
    }    
  }
  span {
    display: block;
    height: 1.6rem;
    line-height: 1.6rem;    
    @media ${device.lg} {
      height: 2rem;
      line-height: 2rem;
    }    
    // transform: translateY(-2px); - need for Okine font
  }
`;

const RedLineStyles = css`
  color: var(--golden-gate-bridge);
  box-shadow: 0 0 0 1px var(--golden-gate-bridge);
  &:hover {
    color: #fff;
    background: var(--golden-gate-bridge);
    box-shadow: 0 0 0 3px var(--golden-gate-bridge);
  }
`;

export const YellowLineButton = styled.button`
  ${ YellowLineStyles }
`;

export const YellowSolidButton = styled(YellowLineButton)`
  background: ${({ theme }) => theme.colors.brightYellow };
  color: ${({ theme }) => theme.colors.veryDarkBlue};
`;

export const YellowSolidLink = styled(Link)`
  ${ YellowLineStyles }
  background: ${({ theme }) => theme.colors.brightYellow };
  color: ${({ theme }) => theme.colors.veryDarkBlue};  
  text-decoration: none;
  gap: 0.4rem;
  &:hover {
    color: ${({ theme }) => theme.colors.veryDarkBlue};  
  }
`;

export const RedLineButton = styled(YellowLineButton)`
  ${ RedLineStyles }
`;

export const RedLineLink = styled(Link)`
  ${ YellowLineStyles }
  color: var(--golden-gate-bridge);
  box-shadow: 0 0 0 1px var(--golden-gate-bridge);
  text-decoration: none;
  &:hover {
    color: #fff;
    background: var(--golden-gate-bridge);
    box-shadow: 0 0 0 3px var(--golden-gate-bridge);
  }
`;