import { css } from 'styled-components';

// import bg_thin from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Thin.woff2';
// import bg_light from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Light.woff2';
// import bg_regular from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff2';
// import bg_medium from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Medium.woff2';
// import bg_bold from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Bold.woff2';
// import bg_black from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Black.woff2';

import OkineSansLight from '../assets/fonts/okine/made-okine-sans-light/webfonts/made-okine-sans-light.woff2';
import OkineSansMedium from '../assets/fonts/okine/made-okine-sans-regular/webfonts/made-okine-sans-regular.woff2';
import OkineSansBold from '../assets/fonts/okine/made-okine-sans-bold/webfonts/made-okine-sans-bold.woff2';
import OkineSansLightOutline from '../assets/fonts/okine/made-okine-sans-light-outline/webfonts/made-okine-sans-light-outline.woff2';
import OkineSansMediumOutline from '../assets/fonts/okine/made-okine-sans-regular-outline/webfonts/made-okine-sans-regular-outline.woff2';
  /*
  100 – Thin.
  200 – Extra Light (Ultra Light)
  300 – Light.
  400 – Normal.
  500 – Medium.
  600 – Semi Bold (Demi Bold)
  700 – Bold.
  800 – Extra Bold (Ultra Bold)
  */

export default css`

  // BRANDON GROTESQUE FONT _+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_
/*
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_thin }) format('woff2');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_light }) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_regular }) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_medium }) format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_bold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'bg';	
    src: url(${ bg_black }) format('woff2');
    font-weight: 900;
    font-style: normal;
  }
*/






/*
  // OKINE FONT _+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_
  // @font-face {
  //   font-family: 'Okine';	
  // 	src: url('../assets/fonts/okine/made-okine-sans-thin/webfonts/made-okine-sans-thin.woff2') format('woff2');
  // 	font-weight: 100;
  // 	font-style: normal;
  // }
*/
  @font-face {
    font-family: 'Okine';	
    src: url(${ OkineSansLight }) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Okine';	
    src: url(${ OkineSansMedium }) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
/*
  // @font-face {
  //   font-family: 'Okine';	
  // 	src: url('../assets/fonts/okine/made-okine-sans-medium/webfonts/made-okine-sans-medium.woff2') format('woff2');
  // 	font-weight: 500;
  // 	font-style: normal;
  // }
*/
  @font-face {
    font-family: 'Okine';	
    src: url(${ OkineSansBold }) format('woff2');
    font-weight: 600;
    font-style: normal;
  }
/*
  // @font-face {
  //   font-family: 'Okine';	
  // 	src: url('../assets/fonts/okine/made-okine-sans-black/webfonts/made-okine-sans-black.woff2') format('woff2');
  // 	font-weight: 700;
  // 	font-style: normal;
  // }
*/

  /* Outline -=-=-=- */
  /*
  @font-face {
    font-family: 'Okine-Outline';	
    src: url('../assets/fonts/okine/made-okine-sans-thin-outline/webfonts/made-okine-sans-thin-outline.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
  }
  */
  @font-face {
    font-family: 'Okine-Outline';	
    src: url(${ OkineSansLightOutline }) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Okine-Outline';	
    src: url(${ OkineSansMediumOutline }) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  /*
  @font-face {
    font-family: 'Okine-Outline';	
    src: url('../assets/fonts/okine/made-okine-sans-medium-outline/webfonts/made-okine-sans-medium-outline.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Okine-Outline';	
    src: url('../assets/fonts/okine/made-okine-sans-bold-outline/webfonts/made-okine-sans-bold-outline.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Okine-Outline';	
    src: url('../assets/fonts/okine/made-okine-sans-black-outline/webfonts/made-okine-sans-black-outline.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  */

  /* // INFINITY FONT _+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_
  @font-face {
    font-family: 'Infinity';	
    src: url('../assets/fonts/infinity/made-infinity-thin/webfonts/made-infinity-thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity';	
    src: url('../assets/fonts/infinity/made-infinity-light/webfonts/made-infinity-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity';	
    src: url('../assets/fonts/infinity/made-infinity-regular/webfonts/made-infinity-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity';	
    src: url('../assets/fonts/infinity/made-infinity-medium/webfonts/made-infinity-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity';	
    src: url('../assets/fonts/infinity/made-infinity-black/webfonts/made-infinity-black.woff2') format('woff2');
    font-weight: 700;
    font-style: bold;
  }
  */
  /* Outline -=-=-=- */
  /*
  @font-face {
    font-family: 'Infinity-Outline';	
    src: url('../assets/fonts/infinity/made-infinity-outline-thin/webfonts/made-infinity-outline-thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity-Outline';	
    src: url('../assets/fonts/infinity/made-infinity-outline-light/webfonts/made-infinity-outline-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity-Outline';	
    src: url('../assets/fonts/infinity/made-infinity-outline-regular/webfonts/made-infinity-outline-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity-Outline';	
    src: url('../assets/fonts/infinity/made-infinity-outline-medium/webfonts/made-infinity-outline-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity-Outline';	
    src: url('../assets/fonts/infinity/made-infinity-outline-black/webfonts/made-infinity-outline-black.woff2') format('woff2');
    font-weight: 700;
    font-style: bold;
  }
  */
  /* Beside (letters touching) -=-=-=- */
  /*
  @font-face {
    font-family: 'Infinity-Beside';	
    src: url('../assets/fonts/infinity/made-infinity-beside-medium/webfonts/made-infinity-beside-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Infinity-Beside';	
    src: url('../assets/fonts/infinity/made-infinity-beside-black/webfonts/made-infinity-beside-black.woff2') format('woff2');
    font-weight: 700;
    font-style: bold;
  }
  */
`;