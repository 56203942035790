import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const ORIGINAL_defaultColors = {
  primary: "#495fef",
  secondary: "#f5f5f7",
  white: "#ffffff",
  dark: "#161c2d",
  ash: "#413e65",
  black: "#000000",
  warning: "#fedc5a",
  success: "#56b381",
  info: "#482EC3",
};

/*
https://coolors.co/ffd500-ff8c61-ce6a85-985277-5c374c
--gold: hsla(50, 100%, 50%, 1);
--coral: hsla(16, 100%, 69%, 1);
--blush: hsla(344, 51%, 61%, 1);
--magenta-haze: hsla(328, 30%, 46%, 1);
--eggplant: hsla(326, 25%, 29%, 1);

https://coolors.co/ffd500-666a86-788aa3-92b6b1-ff715b
--gold: hsla(50, 100%, 50%, 1);
--paynes-gray: hsla(233, 14%, 46%, 1);
--slate-gray: hsla(215, 19%, 55%, 1);
--cambridge-blue: hsla(172, 20%, 64%, 1);
--bittersweet: hsla(8, 100%, 68%, 1);

https://coolors.co/ffd500-ba5624-381d2a-b4adea-59ffa0
--gold: hsla(50, 100%, 50%, 1);
--burnt-orange: hsla(20, 68%, 44%, 1);
--dark-purple: hsla(331, 32%, 17%, 1);
--periwinkle: hsla(247, 59%, 80%, 1);
--spring-green: hsla(146, 100%, 67%, 1);

https://coolors.co/ffd500-083d77-da4167-f78764-fec9f1
--gold: hsla(50, 100%, 50%, 1);
--yale-blue: hsla(211, 87%, 25%, 1);
--cerise: hsla(345, 67%, 55%, 1);
--coral: hsla(14, 90%, 68%, 1);
--pink-lavender: hsla(315, 96%, 89%, 1);

https://coolors.co/ffd500-08605f-177e89-598381-2f0a28
--gold: hsla(50, 100%, 50%, 1);
--caribbean-current: hsla(179, 85%, 20%, 1);
--teal: hsla(186, 71%, 31%, 1);
--hookers-green: hsla(177, 19%, 43%, 1);
--dark-purple: hsla(311, 65%, 11%, 1);

https://coolors.co/ffd500-131620-738290-f24c00-2f0a28
--gold: hsla(50, 100%, 50%, 1);
--rich-black: hsla(226, 25%, 10%, 1);
--slate-gray: hsla(209, 12%, 51%, 1);
--golden-gate-bridge: hsla(19, 100%, 47%, 1);
--dark-purple: hsla(311, 65%, 11%, 1);
*/

const defaultColors = {
  primary: "hsla(210, 80%, 50%, 1.000)",
  secondary: "#f5f5f7",
  white: "#ffffff",
  dark: "hsla(224, 20%, 10%, 1.000)",
  ash: "#413e65",
  black: "#000000",
  warning: "#fedc5a",
  success: "#56b381",
  info: "#482EC3",
  yellow: "hsla(60, 100%, 50%, 1)",
  brightYellow: "hsla(50, 100%, 50%, 1.000)",
  red: "hsla(5, 80%, 50%, 1.000)",
  veryDarkBlue: "hsla(225, 25%, 10%, 1.000)",
  mainBackgroundColor: "hsla(50,100%,98%,1.000)"
};

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.5),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.5),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  bgShade: rgba(defaultColors.white, 0.5),
  front: defaultColors.dark,
  frontShade: rgba(defaultColors.dark, 0.5),
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  // text: rgba(defaultColors.dark, 0.7),
  warning: defaultColors.warning,
  success: defaultColors.success,
  info: defaultColors.info,
  yellow: defaultColors.yellow,
  brightYellow: defaultColors.brightYellow,
  mainBackgroundColor: defaultColors.mainBackgroundColor,
  veryDarkBlue: defaultColors.veryDarkBlue,
  red: defaultColors.red,
  text: defaultColors.veryDarkBlue,

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.5),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.5),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      bgShade: rgba(defaultColors.dark, 0.5),
      front: defaultColors.white,
      frontShade: rgba(defaultColors.white, 0.5),
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      // text: rgba(defaultColors.white, 0.7),
      text: defaultColors.veryDarkBlue,
      warning: defaultColors.warning,
      success: defaultColors.success,
      info: defaultColors.info,
    },
  },
};

const theme = {
  initialColorModeName: 'light',
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${ breakpoints.sm }px`,
    `${ breakpoints.md }px`,
    `${ breakpoints.lg }px`,
    `${ breakpoints.xl }px`,
  ],
};

export default theme;
