import React from 'react';
import { Container } from "react-bootstrap";
import styled from 'styled-components';
// import Logo from "../Logo";

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-out;
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

const Drawer = styled.div`
  position: fixed;
  width: 20rem;
  max-width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 999999;
  background: ${({ theme }) => theme.colors.mainBackgroundColor};
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.3s ease-out;
  &.hidden {
    transform: translateX(100%);
  }
  .dropdown-menu {
    position: static !important;
    will-change: initial !important;
    transform: initial !important;
    float: none !important;
  }
  .sidenav-wrap {
    padding: 1rem;
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  height: 2.4rem;
  width: 100%;
  margin: 8px 0 3rem 0;
  padding-right: 8px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  color: var(--creative-gray);
  &:hover {
    color: ${({ theme }) => theme.colors.red}; 
  }
  svg {
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;    
    display: block;
  }
`;

const CloseButton = (props) => (
  <CloseWrapper { ...props } >
    <svg
      role="img"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
      ></path>
    </svg>
  </CloseWrapper>
);

const Offcanvas = ({ show, onHideOffcanvas, children, ...rest }) => {
  if (typeof document !== "undefined") {
    if (show) {
      document.querySelector("html").classList.add("has-offcanvas");
      document.body.classList.add("has-offcanvas");
    } else {
      document.querySelector("html").classList.remove("has-offcanvas");
      document.body.classList.remove("has-offcanvas");
    }
  }

  return (
    <div {...rest}>

      <Overlay className={show ? "" : "hidden"} onClick={onHideOffcanvas} />

      <Drawer className={show ? "" : "hidden"}>

        <Container>

          <div className="sidenav-wrap">

              <CloseButton onClick={onHideOffcanvas} />
           

            <div className="pt-4">{children}</div>

          </div>

        </Container>

      </Drawer>

    </div>
  );
};

export default Offcanvas;
