import React, { useState }from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { device } from "../../utils";

import CCLogo from "../../assets/logo/website-header-logo@2x.png";
import CCLogoSmall from "../../assets/logo/website-header-logo-small@2x.png";
import CCLogoTypeMini from "../../assets/logo/cc-logotype-mini@2x.png";

const LogoLink = styled(Link)`
  height: 140px;
  width: 140px;
  z-index: 1;

  @media ${device.md} {
    height: 235px;
    width: 235px;
  }

  ${'' /* @media ${device.sm} {
    background: red;
  }

  @media ${device.lg} {
    background: green;
  } */}
`;

const LogoLinkSmall = styled(Link)`
  display: block;
  height: 60px;
  width: 122px;
  z-index: 1;
  margin-right: 10px;
`;

const CcLogoImg = styled.img`
  height: 100px;
  width: 100px;
  display: block;
  @media ${device.md} {
    height: 140px;
    width: 140px;
    right: 63px;
    top: 39px;
    position: relative;
  }
  @media (min-width: 900px) {
    right: unset;
    top: unset;
  }
  @media ${device.lg} {
    height: 235px;
    width: 235px;
    right: 105px;
    top: 65px;
  }
  @media (min-width: 1240px) {
    right: unset;
    top: unset;
  }
`;

const CcLogoSmallImg = styled.img`
  height: 60px;
  width: 122px;
  display: block;
`;

const MiniLogoType = styled.img`
  width: 60px;
  position: absolute;
  top: 8px;
  left: 90px;
  display: block;
  @media ${device.md} {
    top: 10px;
    left: -8px;
  }
  @media ${device.lg} {
    width: 86px;
    height: 36px;
    top: 15px;
    left: 20px;
  }
`;

const Logo = ({ color = 'front', height, className = '', ...rest }) => {
  const [swingChair, setSwingChair] = useState(false);
  const triggerSwingChair = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    if (swingChair === true) {
      return;
    }
    setSwingChair(true);
    setTimeout(() => {
      setSwingChair(false);
    }, 3000);
  };
  // <LogoLink to="/" className={`${ className }`} { ...rest }></LogoLink>
  return (
    <div className={className} { ...rest } onClick={ triggerSwingChair }>

      <CcLogoImg className={`large-chair-logo ${ swingChair ? 'chair-swing-animation' : '' }`} src={ CCLogo } />

      <MiniLogoType src={ CCLogoTypeMini } />

    </div>
  );
};

export const SmallLogo = ({ color = 'front', height, className = '', ...rest }) => {
  return (
    <LogoLinkSmall to="/" className={ className } { ...rest }>

      <CcLogoSmallImg className="small-chair-logo" src={ CCLogoSmall } />

      {/* <Title color={color} variant="cardLg" className="mb-0">
        chairlift creative
      </Title> */}

    </LogoLinkSmall>
  );
};

export default Logo;

