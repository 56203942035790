import { createGlobalStyle } from 'styled-components';
import { device } from '../utils';
import vars from './vars';
import reset from './reset';
import fonts from './fonts';
import layout from './layout';
import navbar from './navbar';
import modal from './modal';

const globalStyle = createGlobalStyle`
  ${vars}
  ${reset}
  ${fonts}

  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }

  html {
    font-size: 10px;
  }

  body {
    background-color: ${({ theme }) => theme.colors.mainBackgroundColor};
    color: ${({ theme }) => theme.colors.text};
    
    letter-spacing: unset;
    
    margin: 0;
    font-family: "Okine", "bg", sans-serif;    
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
   
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(22, 28, 45, 0);

    /* font-family: var(--bs-body-font-family); */
    /* line-height: var(--bs-body-line-height); */    
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.25;
  }

  h6, h5, h4, h3, h2, h1 {
    margin: 0;
    font-family: "Okine", "bg", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    ${'' /* color: var(--bs-heading-color); */}
    color: ${({ theme }) => theme.colors.text};
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p,
  .p-div {
    /* can't put staticImage in <p> - throws error - need to use fake ".p-div" */    
    line-height: 1.6;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 300;
    max-width: 70rem;
    color: ${({ theme }) => theme.colors.text };

    @media ${device.lg} {
      font-size: 2rem;
    }
  }

  h2 + p,
  h3 + p,
  p + p {
    margin-block-start: 1rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a, .text-button {
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.2em;
    transition: 
      color 250ms ease,
      background 250ms ease;

    color: inherit;
    &:hover, &:active, &:focus {
      outline: none;
      color: var(--golden-gate-bridge);
    }
  }

  .text-button {
    background: none;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 0.875em;
  }

  mark {
    padding: 0.1875em;
    color: var(--bs-highlight-color);
    background-color: var(--bs-highlight-bg);
  }

  /* layout */
  ${ layout }

  /* navbar */
  ${ navbar }

  /* modal */
  ${ modal }  

  /* helper classes */
  .overflow-hidden {
    overflow: hidden;
  }

  .stack > * + * {
    margin-block-start: 1.5rem;
  }

  .stack > .stack > * + * {
    margin-block-start: 0.75rem;
  }

  .center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* fixes */
  .modal,
  .modal-backdrop {
    z-index: 99999;
  }

  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1200px;
    }
  }

  .has-offcanvas {
    width: 100%;
    height: 100%;
    /* overflow: hidden !important; */
  }
`;

export default globalStyle;
